<template>
  <div class="view-car" :class="{ 'mb-view-car': isMinDevice }">
    <div class="header flex-c">
      <div class="header-box flex-c" :class="{ 'mb-header-box': isMinDevice }">
        <div
          @click="type = 1"
          class="name point"
          :class="{ 'active-name': type === 1 || type === 2 }">
          Cart
        </div>
        <div
          class="icon"
          :class="{ 'active-icon': type === 1 || type === 2 }"></div>
        <div class="name point" :class="{ 'active-name': type === 2 }">
          Receipt
        </div>
        <div class="icon" :class="{ 'active-icon': type === 2 }"></div>
        <div class="name" :class="{ 'active-name': type === 3 }">
          Confirm Order
        </div>
        <div class="icon" :class="{ 'active-icon': type === 3 }"></div>
        <div class="name" :class="{ 'active-name': type === 4 }">
          Order Completion
        </div>
      </div>
    </div>
    <div
      v-if="type === 1"
      v-loading="loading"
      class="content-box-type1"
      :class="{ 'mb-content-box-type1': isMinDevice }">
      <div v-if="!isMinDevice" class="common-table">
        <div class="left-common flex-s table-header">
          <div style="flex-grow: 1">Products</div>
          <div>Price</div>
          <div>Amount</div>
          <div>Subtotal</div>
          <div></div>
        </div>
        <div
          v-loading="loading"
          class="table-body"
          style="max-height: 5.5rem; overflow-y: auto">
          <div
            v-for="item in carList"
            :key="item.id"
            class="left-common flex-s">
            <div style="flex-grow: 1">
              <div class="good-img">
                <img class="h100" :src="item?.goods?.image" alt="" />
              </div>
              <div class="text">{{ item?.goods?.title }}</div>
            </div>
            <div v-if="isVip">${{ item?.goods?.price }}</div>
            <div v-else>${{ item?.goods?.marketprice }}</div>
            <div>
              <div class="num-box flex-s">
                <div @click.stop="carDownOnClick(item)" class="btn-down"></div>
                <div class="btn-num btn-nums flex-c">
                  <input
                    class="common-input flex-c"
                    :class="{ 'mb-common-input': isMinDevice }"
                    type="text"
                    :value="item.nums"
                    @blur="carChangeOnClick($event, item)" />
                </div>
                <div @click.stop="carUpOnClick(item)" class="btn-up"></div>
              </div>
            </div>
            <div v-if="isVip">
              ${{ (item?.goods?.price * item?.nums).toFixed(1) }}
            </div>
            <div v-else>
              ${{ (item?.goods?.marketprice * item?.nums).toFixed(1) }}
            </div>
            <div>
              <div @click="delCart(item)" class="delete-img point">
                <img
                  class="w100 h100"
                  src="@/assets/images/pc/pc-home-icon13.png"
                  alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isMinDevice" class="mb-common-table">
        <div
          v-for="item in carList"
          :key="item.id"
          class="mb-left-common flex-s">
          <div class="item-left">
            <div class="good-img">
              <img class="h100 w100" :src="item?.goods?.image" alt="" />
            </div>
          </div>
          <div class="item-center">
            <div class="text">{{ item?.goods?.title }}</div>
            <div class="price" v-if="isVip">${{ item?.goods?.price }}</div>
            <div class="vip-price" v-else>${{ item?.goods?.marketprice }}</div>
            <div>
              <div class="num-box flex-s">
                <div @click.stop="carDownOnClick(item)" class="btn-down"></div>
                <div class="btn-num btn-nums flex-c">
                  <input
                    :class="{ 'mb-common-input': isMinDevice }"
                    class="common-input flex-c"
                    type="text"
                    :value="item.nums"
                    @blur="carChangeOnClick($event, item)" />
                </div>
                <div @click.stop="carUpOnClick(item)" class="btn-up"></div>
              </div>
            </div>
          </div>
          <div class="item-right">
            <div @click="delCart(item)" class="delete-img point">
              <img
                class="w100 h100"
                src="@/assets/images/pc/pc-home-icon13.png"
                alt="" />
            </div>
            <div class="mb-total" v-if="isVip">
              ${{ (item?.goods?.price * item?.nums).toFixed(1) }}
            </div>
            <div class="mb-total" v-else>
              ${{ (item?.goods?.marketprice * item?.nums).toFixed(1) }}
            </div>
          </div>
        </div>
      </div>
      <div class="content-right" :class="{ 'mb-content-right': isMinDevice }">
        <div class="title">Total Cart</div>
        <div class="amount-box flex-b">
          <div class="amount-title">total</div>
          <div class="amount-num">${{ carTotalPrice }}</div>
        </div>
        <div class="btn-box flex-c">
          <div @click="nextStep" class="btn flex-c point">
            CONTINUE TO CHECK OUT
          </div>
        </div>
        <div class="fuck-box flex-c">
          <div class="fuck">
            <img
              class="w100"
              :src="onlineUrl + finalFuncImgs[7]"
              alt=""
              srcset="" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="type === 2"
      class="content-box-type2"
      :class="{ 'mb-content-box-type2': isMinDevice }">
      <div class="content2-left">
        <div class="common-title" :class="{ 'mb-common-title': isMinDevice }">
          Receipt Information
        </div>
        <div class="form-box">
          <el-row class="c-row">
            <el-col class="c-col" :span="24">
              <div class="title">Name<span style="color: red">*</span></div>
              <div class="input-box">
                <input
                  style="text-align: left"
                  class="common-input"
                  :class="{ 'mb-common-input': isMinDevice }"
                  type="text"
                  v-model="formData.name"
                  placeholder="name" />
              </div>
            </el-col>
            <!-- <el-col class="c-col" :span="2">
              <div style="height: 1px" class=""></div>
            </el-col> -->
            <!-- <el-col class="c-col" :span="11">
              <div class="title">
                Last Name<span style="color: red">*</span>
              </div>
              <div class="input-box">
                <input
                  style="text-align: left"
                  class="common-input"
                  type="text"
                  placeholder="lastname"
                  v-model="formData.surname"
                />
              </div>
            </el-col> -->
          </el-row>
          <el-row class="c-row">
            <el-col class="c-col" :span="24">
              <div class="title">Email<span style="color: red">*</span></div>
              <div class="input-box">
                <input
                  style="text-align: left"
                  class="common-input"
                  :class="{ 'mb-common-input': isMinDevice }"
                  type="text"
                  placeholder="email"
                  v-model="formData.email" />
              </div>
            </el-col>
            <!-- <el-col class="c-col" :span="2">
              <div style="height: 1px" class=""></div>
            </el-col> -->
            <!-- <el-col class="c-col" :span="11">
              <div class="title">
                Phone
              </div>
              <div class="input-box">
                <input
                  style="text-align: left"
                  class="common-input"
                  type="text"
                  placeholder="mobile"
                  v-model="formData.mobile"
                />
              </div>
            </el-col> -->
          </el-row>
          <el-row class="c-row">
            <!-- <el-col class="c-col" :span="24">
              <div class="title">
                Country/Region<span style="color: red">*</span>
              </div>
              <div class="input-box">
                <input
                  style="text-align: left"
                  class="common-input"
                  type="text"
                  placeholder="country"
                  v-model="formData.country"
                />
              </div>
            </el-col> -->
            <el-col class="c-col" :span="24">
              <div class="title">Phone（Optional）</div>
              <div class="input-box">
                <input
                  style="text-align: left"
                  class="common-input"
                  :class="{ 'mb-common-input': isMinDevice }"
                  type="text"
                  placeholder="mobile"
                  v-model="formData.mobile" />
              </div>
            </el-col>
          </el-row>
          <el-row class="c-row">
            <!-- <el-col class="c-col" :span="24">
              <div class="title">
                Towns/City
              </div>
              <div class="input-box">
                <input
                  style="text-align: left"
                  class="common-input"
                  type="text"
                  placeholder="address"
                  v-model="formData.address"
                />
              </div>
            </el-col> -->
          </el-row>
        </div>
        <div class="common-title" :class="{ 'mb-common-title': isMinDevice }">
          Other Information
        </div>
        <el-row class="c-row">
          <el-col class="c-col" :span="24">
            <div class="title">Remarks（Optional）</div>
            <div class="textarea-box">
              <el-input
                resize="none"
                class="common-textarea"
                :class="{ 'mb-common-textarea': isMinDevice }"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 5 }"
                placeholder="remarks"
                v-model="formData.memo">
              </el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="content2-right">
        <div
          class="common-title p-0-60"
          :class="{ 'mb-common-title': isMinDevice }"
          style="margin-bottom: 0.42rem">
          Your order
        </div>
        <div
          class="common-title p-0-60"
          :class="{ 'mb-common-title': isMinDevice }">
          Products
        </div>
        <div class="right-line"></div>
        <div
          class="good-row-box p-0-60"
          :class="{ 'mb-good-row-box': isMinDevice }">
          <div v-for="item in carList" :key="item.id" class="good-row flex-b">
            <div class="good-row-left">{{ item?.goods?.title }}</div>
            <div v-if="isVip" class="good-row-right">
              ${{ item?.goods?.price }}*{{ item?.nums }}
            </div>
            <div v-else class="good-row-right">
              ${{ item?.goods?.marketprice }}*{{ item?.nums }}
            </div>
          </div>
        </div>
        <div
          class="price-row p-0-60 flex-b"
          :class="{ 'mb-price-row': isMinDevice }">
          <div class="good-row-left">subtotal</div>
          <div class="good-row-right">${{ carTotalPrice }}</div>
        </div>
        <div class="right-line"></div>
        <div
          class="price-row p-0-60 flex-b"
          :class="{ 'mb-price-row': isMinDevice }">
          <div class="good-row-left">total</div>
          <div class="good-row-right">${{ carTotalPrice }}</div>
        </div>
        <div class="right-line"></div>
        <div
          class="price-row p-0-60 flex-b"
          :class="{ 'mb-price-row': isMinDevice }">
          <div class="good-row-left">Payment method</div>
          <div class="good-row-right"></div>
        </div>
        <div
          class="price-row p-0-60 flex-b"
          :class="{ 'mb-price-row': isMinDevice }">
          <div class="good-row-left"></div>
          <div class="good-row-right balance">
            balance：${{ ((userInfo?.money || 0) * 1).toFixed(1) }}
          </div>
        </div>
        <div
          class="price-row p-0-60 flex-b"
          style="margin-bottom: 0.84rem"
          :class="{ 'mb-price-row': isMinDevice }">
          <div v-if="isMinDevice" class="good-row-right"></div>
          <div
            v-if="userInfo?.money * 1 < carTotalPrice * 1"
            class="good-row-left">
            Insufficient balance, please
            <span
              @click="$router.push('/user')"
              class="point"
              style="color: rgb(0, 118, 255)"
              >recharge</span
            >
          </div>
          <div v-if="!isMinDevice" class="good-row-right"></div>
        </div>
        <div v-if="!isMinDevice" class="bottom-btn-box flex-c p-0-60">
          <div
            v-if="userInfo?.money * 1 >= carTotalPrice * 1"
            @click="orderSubmitOnClick"
            class="btn flex-c point">
            CONFIRM PAYMENT
          </div>
          <div v-else class="btn btn-gray flex-c point">CONFIRM PAYMENT</div>
        </div>
      </div>
      <div v-if="isMinDevice" class="mb-bottom-btn-box flex-c p-0-60">
        <div
          v-if="userInfo?.money * 1 >= carTotalPrice * 1"
          @click="orderSubmitOnClick"
          class="btn flex-c point">
          CONFIRM PAYMENT
        </div>
        <div v-else class="btn btn-gray flex-c point">CONFIRM PAYMENT</div>
      </div>
    </div>
    <div
      v-if="type === 3"
      class="content-box-type3"
      :class="{ 'mb-content-box-type3': isMinDevice }">
      <!-- <div class="title text-c">Successful Purchase！</div> -->
      <div v-if="!isMinDevice" class="type3-content flex-c">
        <div class="type3-content-center common-table">
          <div class="left-common flex-s">
            <!-- <div class="row-1 flex-c">Order Number</div> -->
            <div class="row-1 flex-c">Products</div>
            <div class="row-2 flex-c">Price</div>
            <div class="row-3 flex-c">Amount</div>
            <div class="row-4 flex-c">Subtotal</div>
            <!-- <div class="row-5 flex-c"></div> -->
          </div>
          <div class="left-center">
            <div
              v-for="item in snapshotCarList"
              :key="item.id"
              class="left-common flex-s">
              <div class="row-1 flex-c flex-c">
                <div class="img"></div>
                <div class="text">{{ item?.title }}</div>
              </div>
              <div class="row-2 flex-c">
                ${{ isVip ? item?.price : item?.marketprice }}
              </div>
              <div class="row-3 flex-c">
                <div class="flex-b">
                  <div class="btn-nums flex-c">
                    <input
                      class="common-input flex-c"
                      :class="{ 'mb-common-input': isMinDevice }"
                      type="text"
                      :value="item?.nums" />
                  </div>
                </div>
              </div>
              <div class="row-4 flex-c">
                ${{
                  isVip
                    ? (item?.price * item?.nums).toFixed(1)
                    : (item?.marketprice * item?.nums).toFixed(1)
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="type3-content-total">
          Total：${{ orderDetail?.amount || 0 }}
        </div>
      </div>
      <div v-if="isMinDevice" class="mb-type3-content">
        <div class="order-no">OrderNo{{ orderDetail.order_sn }}</div>
        <div
          v-for="item in snapshotCarList"
          :key="item.id"
          class="order-item flex-b">
          <div class="item-left">
            <div class="good-img">
              <img class="h100 w100" :src="item?.image" alt="" />
            </div>
          </div>
          <div class="item-center">
            <div class="title">
              {{ item?.title }}
            </div>
            <div class="price-box flex-s">
              <div class="price">
                ${{ isVip ? item?.price : item?.marketprice }}
              </div>
              <div class="num">X {{ item?.nums }}</div>
            </div>
          </div>
          <div class="item-right">
            ${{
              isVip
                ? (item?.price * item?.nums).toFixed(1)
                : (item?.marketprice * item?.nums).toFixed(1)
            }}
          </div>
        </div>
      </div>
      <div v-if="isMinDevice" class="mb-total">
        Total：${{ orderDetail?.amount || 0 }}
      </div>
      <div
        class="bottom-btn-box flex-b"
        :class="{ 'mb-bottom-btn-box': isMinDevice }"
        style="padding: 0 3rem">
        <div
          @click="
            $router.push({
              path: '/User',
              query: {
                TabIndex: 6,
              },
            })
          "
          class="btn flex-c point">
          CANCEL
        </div>
        <div @click="orderPayFun" class="btn flex-c point">CONFIRM PAYMENT</div>
      </div>
    </div>
    <div
      v-if="type === 4"
      class="content-box-type3"
      :class="{ 'mb-content-box-type3': isMinDevice }">
      <div class="title text-c" :class="{ 'mb-title4': isMinDevice }">
        Successful Purchase！
      </div>
      <div v-if="!isMinDevice" class="type3-content flex-c">
        <div class="type3-content-center common-table">
          <div class="left-common flex-s">
            <!-- <div class="row-1 flex-c">Order Number</div> -->
            <div class="row-1 flex-c">Products</div>
            <div class="row-2 flex-c">Price</div>
            <div class="row-3 flex-c">Amount</div>
            <div class="row-4 flex-c">Subtotal</div>
            <!-- <div class="row-5 flex-c"></div> -->
          </div>
          <div class="left-center">
            <div
              v-for="item in snapshotCarList"
              :key="item.id"
              class="left-common flex-s">
              <div class="row-1 flex-c flex-c">
                <div class="img"></div>
                <div class="text">{{ item?.title }}</div>
              </div>
              <div class="row-2 flex-c">
                ${{ isVip ? item?.price : item?.marketprice }}
              </div>
              <div class="row-3 flex-c">
                <div class="flex-b">
                  <div class="btn-nums flex-c">
                    <input
                      class="common-input flex-c"
                      :class="{ 'mb-common-input': isMinDevice }"
                      type="text"
                      :value="item?.nums" />
                  </div>
                </div>
              </div>
              <div class="row-4 flex-c">
                ${{
                  isVip
                    ? (item?.price * item?.nums).toFixed(1)
                    : (item?.marketprice * item?.nums).toFixed(1)
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isMinDevice" class="mb-type3-content">
        <div
          v-for="item in snapshotCarList"
          :key="item.id"
          class="order-item flex-b">
          <div class="item-left">
            <div class="good-img">
              <img class="h100 w100" :src="item?.image" alt="" />
            </div>
          </div>
          <div class="item-center">
            <div class="title">
              {{ item?.title }}
            </div>
            <div class="price-box flex-s">
              <div class="price">
                ${{ isVip ? item?.price : item?.marketprice }}
              </div>
              <div class="num">X {{ item?.nums }}</div>
            </div>
          </div>
          <div class="item-right">
            ${{
              isVip
                ? (item?.price * item?.nums).toFixed(1)
                : (item?.marketprice * item?.nums).toFixed(1)
            }}
          </div>
        </div>
      </div>
      <div v-if="isMinDevice" class="mb-total">
        Total：${{ orderDetail?.amount || 0 }}
      </div>
      <div
        class="bottom-btn-box flex-c"
        :class="{ 'mb-bottom-btn-box': isMinDevice }">
        <div
          @click="
            $router.push({
              path: '/User',
              query: {
                TabIndex: 2,
              },
            })
          "
          class="btn flex-c point">
          GO TO THE WAREHOUSE
        </div>
      </div>
    </div>
    <van-overlay :show="overlay1Show">
      <div class="overlay1" :class="{ 'mb-overlay1': isMinDevice }">
        <div class="close-btn" @click="overlay1Show = false"></div>
        <div class="overlay1-header flex-c">Keys</div>
        <div class="overlay1-center">
          <div
            class="goods-box"
            v-loading="aLoading"
            element-loading-text="extracting data..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 0.3)">
            <div v-if="isNoStock" class="is-no-stock flex-c">
              Please contact website customer service
            </div>
            <div v-else class="is-stock">
              <div v-for="(item, index) in keys" :key="index" class="item">
                <div v-if="item.keys.length" class="item-title">
                  {{ item.title }}
                </div>
                <div class="item-text-list">
                  <div
                    v-for="(vItem, vIndex) in item.keys"
                    :key="index + '#' + vIndex"
                    class="item-text">
                    {{ vItem }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="header-text">
            If a key record is needed in the future, the key history can be
            found in the warehouse of the personal center
          </div>
          <div class="btn-box flex-b">
            <div
              v-if="!isNoStock && !aLoading"
              @click="copyOnClick"
              class="btn1 flex-c point">
              Copy
            </div>
            <div
              v-if="!isNoStock && !aLoading"
              @click="exportOnClick"
              class="btn2 flex-c point">
              Export
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  delCarApi,
  orderSubmitApi,
  orderPayApi,
  changeCarNumApi,
  getAddressApi,
  getOrderDetailApi,
  payGetKeysApi,
} from "@/network/api.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { validateEmail } from "@/utils/common.js";
import { exportExcel } from "@/utils/excel.js";
export default {
  data() {
    return {
      value: 1,
      type: 1,
      formData: {
        name: "",
        surname: "",
        email: "",
        mobile: "",
        country: "",
        address: "",
        memo: "",
      },
      snapshotCarList: [], // 购物车快照
      orderDetail: null,
      loading: false,
      order_sn: "",
      overlay1Show: false,
      keys: [],
      byCarList: [],
      Timer: "",
      aLoading: true,
    };
  },
  created() {
    this.init();
  },
  watch: {
    type: {
      handler() {
        this.ReFreshUserInfo();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["isVip", "finalFuncImgs"]),
    ...mapState(["onlineUrl", "carList", "userInfo", "isMinDevice"]),
    carTotalPrice() {
      let totle = 0;
      this.carList.forEach((item) => {
        if (this.isVip) {
          totle += item?.goods?.price * item?.nums;
        } else {
          totle += item?.goods?.marketprice * item?.nums;
        }
      });
      console.log(123, totle);
      return totle.toFixed(1);
    },
    isNoStock() {
      let flag = false;
      this.byCarList.forEach((item) => {
        if (item?.goods?.stocks < item?.nums) {
          flag = true;
          this.aLoading = false;
        }
      });
      return flag;
    },
  },
  methods: {
    ...mapActions(["getCartList", "ReFreshUserInfo"]),
    ...mapMutations(["setShowTipPopup"]),
    init() {
      setTimeout(() => {
        document.body.scrollIntoView(".view-car");
      }, 0);
      this.type = this.$route.query?.carType * 1 || 1;
      this.getCartList();

      this.getUserAddress();
    },
    copyOnClick() {
      const array = [];
      this.keys.forEach((item) => {
        array.push(...item.keys);
      });
      console.log(12345, array);
      const string = array.join(",");
      console.log("复制", string);
      this.$copyText(string);
    },
    exportOnClick() {
      // exportExcel("exported tables", exc_data);
      const array = [["key"]];
      this.keys.forEach((item) => {
        item.keys.forEach((vItem) => {
          if (vItem) {
            array.push([vItem]);
          }
        });
      });
      console.log(array);
      exportExcel("keys", array);
    },
    async getUserAddress() {
      const res = await getAddressApi();
      if (res.data) {
        this.formData = {
          ...this.formData,
          ...res.data,
        };
      }
    },
    async carDownOnClick(item) {
      if (this.loading) {
        this.$message("wait");
        return false;
      }
      if (item.nums > 1) {
        // this.loading = true;
        this.changeNums(item, item.nums - 1);
      } else {
        this.$message.error("The minimum quantity of products is 1");
      }
    },
    async carChangeOnClick(e, item) {
      if (this.loading) {
        this.$message("wait");
        return false;
      }
      // this.loading = true;
      const value = e.target?.value;
      item.nums = value;
      this.$forceUpdate();
      this.changeNums(item, value);
    },
    async carUpOnClick(item) {
      if (this.loading) {
        this.$message("wait");
        return false;
      }
      this.changeNums(item, item.nums + 1);
    },
    async changeNums(item, num) {
      const params = {
        id: item.id,
        nums: num,
        type: "nums",
      };
      try {
        const res = await changeCarNumApi(params);
      } catch (error) {
        this.setShowTipPopup(true);
      }
      try {
        await this.getCartList();
      } catch (error) {}
      this.loading = false;
    },
    nextStep() {
      if (this.carList.length === 0) {
        this.$message.error("Product quantity is empty");
        return false;
      }
      this.type += 1;
      // this.snapshotCarList = this.carList;
    },
    async orderSubmitOnClick() {
      const { email, name } = this.formData;
      if (!email || !name) {
        this.$message.error("name,email required");
        return false;
      }
      if (!validateEmail(this.formData.email)) {
        this.$message.error("Email is not valid");
        return false;
      }
      const params = {
        ids: this.carList.map((item) => item.id),
        ...this.formData,
      };
      this.byCarList = JSON.parse(JSON.stringify(this.carList));
      const res = await orderSubmitApi(params);
      this.order_sn = res.data?.order_sn;
      this.getOrderDetailFun();
      this.getCartList();
      // this.orderPayFun(res.data?.order_sn);
    },

    async getOrderDetailFun() {
      const params = {
        order_sn: this.order_sn,
      };
      const res = await getOrderDetailApi(params);
      console.log("订单详情", res);
      this.orderDetail = res.data;
      this.snapshotCarList = res.data?.order_goods;
      console.log("快照", this.snapshotCarList);
      this.type += 1;
    },
    async payGetKeysFun(order_sn) {
      const params = {
        order_sn,
      };
      setTimeout(() => {
        if (this.Timer) {
          clearInterval(this.Timer);
        }
        if ((this.aLoading = true)) {
          this.aLoading = false;
          this.isNoStock = true;
        }
      }, 90000);
      if (this.Timer) {
        clearInterval(this.Timer);
      }
      const res = await payGetKeysApi(params);
      if (res?.data) {
        this.keys = res.data;
        this.aLoading = false;
        return false;
      }
      this.Timer = setInterval(async () => {
        const res = await payGetKeysApi(params);
        if (res?.data) {
          this.keys = res.data;
          this.aLoading = false;
          clearInterval(this.Timer);
        }
      }, 5000);
    },
    async orderPayFun() {
      if (this.order_sn) {
        const params = {
          order_sn: this.order_sn,
        };
        try {
          const res = await orderPayApi(params);
          await this.payGetKeysFun(this.order_sn);
          this.ReFreshUserInfo();
          this.type += 1;
          this.overlay1Show = true;
        } catch (error) {
          // console.log(333);
          // this.$message.error(error);
        }
      } else {
        this.$message.error("error");
      }
    },
    async delCart(item) {
      const params = {
        ids: item.id,
      };
      const res = await delCarApi(params);
      this.getCartList();
    },
  },
};
</script>

<style lang="scss">
.view-car {
  padding: 1.1rem 2.23rem 1.8rem;
  .overlay1 {
    width: 8.5rem;
    min-height: 3rem;
    position: absolute;
    left: 50%;
    top: 50%;
    background: #fff;
    transform: translate(-50%, -50%);
    z-index: 999;
    position: relative;
    color: #fff;
    border-radius: 0.1rem;
    overflow: hidden;
    .close-btn {
      width: 0.35rem;
      height: 0.35rem;
    }
    .overlay1-header {
      width: 100%;
      height: 0.81rem;
      background: #09264d;
      font-size: 0.28rem;
    }
    .overlay1-center {
      padding: 0.2rem 0.4rem;
      box-sizing: border-box;
      .header-text {
        color: #000;
        font-size: 0.14rem;
        margin-bottom: 0.2rem;
        font-weight: bold;
      }
      .goods-box {
        width: 100%;
        height: 3.52rem;
        background: #f6faff;
        overflow-y: auto;
        margin-bottom: 0.2rem;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: start;
        padding: 0.2rem 0.1rem;
        box-sizing: border-box;
        .is-no-stock {
          width: 100%;
          height: 100%;
        }
        .is-stock {
          .item {
            .item-title {
              font-weight: 500;
              font-size: 16px;
              margin-bottom: 0.1rem;
            }
            .item-text-list {
              .item-text {
                font-size: 14px;
                margin-bottom: 0.08rem;
              }
            }
          }
        }
      }
      .btn-box {
        font-size: 0.2rem;
        color: #fff;
        .btn1 {
          width: 3.18rem;
          height: 0.58rem;
          background: #0076ff;
          border-radius: 0.04rem;
        }
        .btn2 {
          width: 3.18rem;
          height: 0.58rem;
          background: #0076ff;
          border-radius: 0.04rem;
        }
      }
    }
  }
  .mb-overlay1 {
    width: 16rem;
    height: 17rem;
    .close-btn {
      width: 1.2rem;
      height: 1.2rem;
      right: 0.36rem;
      top: 0.36rem;
    }
    .overlay1-header {
      height: 2rem;
      font-size: 0.78rem;
    }
    .overlay1-center {
      padding: 0.4rem 0.8rem;
      .header-text {
        font-size: 0.6rem;
        margin-bottom: 1rem;
      }
      .goods-box {
        height: 9.3rem;
        .is-no-stock {
          font-size: 0.7rem;
        }
      }
      .btn-box {
        font-size: 0.6rem;
        .btn1 {
          width: 6rem;
          height: 1.2rem;
          background: #0076ff;
          border-radius: 0.2rem;
        }
        .btn2 {
          width: 6rem;
          height: 1.2rem;
          background: #0076ff;
          border-radius: 0.2rem;
        }
      }
    }
  }
  .p-0-60 {
    padding: 0 0.6rem;
  }

  .common-title {
    font-size: 0.24rem;
    font-weight: 600;
    color: #002550;
    margin-bottom: 0.42rem;
  }
  .mb-common-title {
    font-size: 0.7168rem;
  }

  .header {
    margin-bottom: 0.25rem;

    .header-box {
      border: 1px solid rgba(208, 208, 208, 0.7);
      box-shadow: rgba(208, 208, 208, 1) 0.01rem 0.01rem 1rem;
      width: 100%;
      padding: 0.14rem 0;

      .name {
        margin-right: 0.35rem;
        font-size: 0.42rem;
        font-weight: 300;
        color: #cdcdcd;
      }

      .active-name {
        font-weight: 500;
        color: #000;
      }

      .icon {
        width: 0.25rem;
        height: 0.2639rem;
        margin-right: 0.35rem;
        background: url("~@/assets/images/pc/pc-home-icon15.png") no-repeat;
        background-size: 100% 100%;
      }

      .active-icon {
        background: url("~@/assets/images/pc/pc-mall-icon1.png") no-repeat;

        background-size: 100% 100%;
      }
    }
    .mb-header-box {
      box-shadow: unset;
      border-color: rgba(101, 114, 134, 1);
      padding: 0.4608rem 0.512rem;
      margin-bottom: 0.6144rem;

      .name {
        font-size: 0.6144rem;
        margin-right: 0.3072rem;
        white-space: nowrap;
      }
      .icon {
        width: 0.4608rem;
        height: 0.512rem;
      }
    }
  }

  .content-box-type1 {
    display: flex;
    align-items: flex-start;

    // .content-left {
    //   width: 8.84rem;
    //   flex-shrink: 0;
    //   margin-right: 0.24rem;
    //   background: rgba(162, 205, 255, 0.1);
    // }

    .content-right {
      flex-grow: 1;
      padding: 0.19rem 0.5rem 0.4rem;
      box-sizing: border-box;
      background: rgba(162, 205, 255, 0.1);

      .title {
        font-size: 0.24rem;
        margin-bottom: 0.73rem;
      }

      .amount-box {
        font-size: 0.18rem;
        margin-bottom: 0.52rem;
      }

      .btn-box {
        margin-bottom: 0.65rem;

        .btn {
          width: 2.89rem;
          height: 0.5rem;
          background: rgba(0, 118, 255, 1);
          font-size: 0.18rem;
          font-weight: 900;
          color: #fff;
          border-radius: 0.04rem;
        }
      }

      .fuck-box {
        .fuck {
          width: 4.66rem;
          // height: 2.51rem;
          // background: #eee;
        }
      }
    }
    .mb-content-right {
      padding: 0.6144rem;
      .title {
        font-size: 0.7168rem;
      }
      .amount-box {
        font-size: 0.7168rem;
      }
      .btn-box {
        justify-content: flex-end;
        .btn {
          width: 10.0358rem;
          height: 1.3825rem;
          font-size: 0.6144rem;
          border-radius: 0.14rem;
        }
      }
      .fuck-box {
        .fuck {
          width: 100%;
        }
      }
    }
  }
  .mb-content-box-type1 {
    flex-wrap: wrap;
    .mb-common-table {
      padding: 0 0.512rem;
      box-sizing: border-box;
      background: rgba(162, 205, 255, 0.1);
      margin-bottom: 0.6144rem;
      .mb-left-common {
        height: 3.789rem;
        padding: 0.6144rem 0;
        border-bottom: 0.0512rem solid rgba(36, 75, 120, 0.2);
        .item-left {
          margin-right: 0.6656rem;
          .good-img {
            width: 2.765rem;
            height: 3.789rem;
            // max-width: 3rem;
          }
        }
        .item-center {
          flex-grow: 1;
          display: flex;
          align-content: space-between;
          flex-wrap: wrap;
          height: 100%;
          padding: 0.2rem 0;
          box-sizing: border-box;
          > div {
            width: 100%;
            font-size: 0.6144rem;
          }
          .delete-img {
            width: 0.3rem;
            height: 0.3rem;
          }
          .num-box {
            width: 3.9104rem;
            height: 1.0241rem;
            @include pcurl("pc-home-icon24.png");
            .btn-down {
              width: 1.0241rem;
              height: 1.0241rem;
              // background: red;
              cursor: pointer;
            }
            .btn-nums {
              flex: 1;
              height: 1.0241rem;
              font-size: 0.6144rem;
            }
            .btn-up {
              width: 1.0241rem;
              height: 1.0241rem;
              // background: green;
              cursor: pointer;
            }
          }
        }
        .item-right {
          display: flex;
          align-content: space-between;
          padding: 0.2rem 0;
          justify-content: flex-end;
          flex-wrap: wrap;
          height: 100%;
          .delete-img {
            width: 0.9217rem;
            height: 0.9217rem;
          }
          .mb-total {
            font-size: 0.7168rem;
          }
        }
      }
      .mb-left-common:nth-last-of-type(1) {
        border-bottom: unset;
      }
    }
    .content-right {
    }
  }

  .content-box-type2 {
    display: flex;
    align-items: flex-start;
    .c-row {
      margin-bottom: 0.18rem;

      .c-col {
        .title {
          margin-bottom: 0.08rem;
          font-size: 0.2rem;
          color: rgba(121, 121, 121, 1);
        }

        .input-box {
          width: 100%;
          height: 0.48rem;
          border-radius: 0.04rem;
          border: 1px solid #cdcdcd;
          background: #fff;
        }

        .textarea-box {
          width: 100%;
          border-radius: 0.04rem;
          background: #fff;
          // .el-textarea__inner,
          // .el-textarea {
          //   border: 0;
          //   width: 100%;
          //   height: 100%;
          //   background: unset;
          //   text-align: left;
          //   // font-size: 0.2rem;
          //   color: #000;
          // }
          // .el-textarea__inner::placeholder {
          //   font-size: 0.2rem;
          //   color: #ccc;
          // }
        }
      }
    }

    .content2-left {
      width: 8.76rem;
      flex-shrink: 0;
      margin-right: 0.32rem;
      background: rgba(162, 205, 255, 0.1);
      padding: 0.5rem 0.6rem 0.68rem;
      box-sizing: border-box;

      .form-box {
        margin-bottom: 0.42rem;
      }
    }

    .content2-right {
      flex-grow: 1;
      box-sizing: border-box;
      background: rgba(162, 205, 255, 0.1);
      padding: 0.5rem 0rem 0.68rem;

      .right-line {
        width: 100%;
        border-bottom: 1px solid rgba(36, 75, 120, 0.2);
        margin-bottom: 0.19rem;
      }

      .good-row-box {
        margin-bottom: 0.6rem;

        .good-row {
          margin-bottom: 0.36rem;
          font-size: 0.2rem;
        }
      }
      .mb-good-row-box {
        .good-row {
          font-size: 0.6144rem;
          color: rgba(75, 75, 75, 1);
        }
      }

      .price-row {
        font-size: 0.2rem;
        font-weight: 500;
        margin-bottom: 0.18rem;
        .balance {
          font-size: 0.28rem;
          color: #66cf73;
        }
      }
      .mb-price-row {
        font-size: 0.6144rem;
        .balance {
          font-size: 0.6144rem;
        }
      }

      .bottom-btn-box {
        .btn {
          width: 2.29rem;
          height: 0.5rem;
          border-radius: 0.04rem;
          background: rgba(0, 118, 255, 1);
          font-size: 0.18rem;
          font-weight: 900;
          color: #fff;
        }
        .btn-gray {
          background: #eee;
          color: #ccc;
        }
      }
    }
    .mb-bottom-btn-box {
      width: 100%;
      margin-top: 1.0241rem;
      margin-bottom: 1.5361rem;
      .btn {
        width: 8.9094rem;
        height: 1.5361rem;
        border-radius: 0.14rem;
        background: rgba(0, 118, 255, 1);
        font-size: 0.6144rem;
        font-weight: 900;
        color: #fff;
      }
      .btn-gray {
        background: #eee;
        color: #ccc;
      }
    }
  }
  .mb-content-box-type2 {
    flex-wrap: wrap;
    .c-row {
      .c-col {
        .title {
          font-size: 0.6144rem;
          margin-bottom: 0.512rem;
        }
        .input-box {
          height: 1.5361rem;
        }
      }
    }
    .content2-left {
      width: 100%;
      margin-bottom: 0.6144rem;
      margin-right: 0;
      font-size: 0.7168rem;
    }
    .content2-right {
      width: 100%;
    }
  }

  .content-box-type3 {
    margin-top: 0.75rem;

    .title {
      font-size: 0.43rem;
      margin-bottom: 0.5rem;
    }

    .type3-content {
      background: rgba(162, 205, 255, 0.1);
      margin-bottom: 0.6rem;
      padding: 0.25rem 0;
      flex-wrap: wrap;

      .type3-content-center {
        width: 8.76rem;
        background: #fff;
        margin-bottom: 0.2rem;
        .row-1 {
          width: 43%;
        }
        .left-center {
          max-height: 4rem;
          overflow-y: auto;
        }
      }
      .type3-content-total {
        width: 100%;
        text-align: center;
        font-size: 0.28rem;
      }
    }

    .bottom-btn-box {
      .btn {
        width: 2.72rem;
        height: 0.58rem;
        border-radius: 0.04rem;
        background: rgba(0, 118, 255, 1);
        font-size: 0.18rem;
        font-weight: 900;
        color: #fff;
      }
      .btn-gray {
        background: #eee;
      }
    }
    .mb-bottom-btn-box {
      width: 100%;
      padding: 0 !important;
      margin-bottom: 2rem;
      .btn {
        width: unset;
        height: unset;
        padding: 0.4608rem 1.2289rem;
        font-size: 0.7168rem;
        border-radius: 0.1rem;
      }
    }
  }
  .mb-content-box-type3 {
    .mb-title4 {
      font-size: 0.9217rem;
      color: rgba(0, 118, 255, 1);
    }
    .mb-type3-content {
      width: 100%;
      background: rgba(162, 205, 255, 0.1);
      margin-bottom: 1.0241rem;
      .order-no {
        border-bottom: 0.0312rem solid rgba(36, 75, 120, 0.2);
        padding: 0.2048rem 0.6144rem;
        box-sizing: border-box;
        font-size: 0.6144rem;
      }
      .order-item {
        border-bottom: 0.0312rem solid rgba(36, 75, 120, 0.2);
        padding: 0.6144rem 1.0241rem;
        box-sizing: border-box;
        font-size: 0.6144rem;
        .item-left {
          margin-right: 0.6656rem;
          .good-img {
            width: 2.765rem;
            height: 3.789rem;
          }
        }
        .item-center {
          // display: flex;
          // align-items: center;
          // justify-content: flex-start;
          .title {
            font-size: 0.6144rem;
            margin-bottom: 0.3072rem;
          }
          .price-box {
            .price {
              margin-right: 3rem;
            }
          }
        }
        .item-right {
          margin-left: auto;
        }
      }
      .order-item:nth-last-of-type(1) {
        border-bottom: 0;
      }
    }
    .mb-total {
      font-size: 0.7168rem;
      color: rgba(0, 37, 80, 1);
      text-align: center;
      margin-bottom: 1.0241rem;
    }
  }
}
.mb-view-car {
  padding: 0.6144rem 1.0241rem;
}
</style>
